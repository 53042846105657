import React from "react";
import AvInstitucion from "../avance-instituciones/av-institucion";

const AvInstituciones = () => {
  return (
    <section className="section-container">
      <AvInstitucion/>
    </section>
  );
};

export default AvInstituciones;
