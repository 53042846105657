import React from 'react'
import DetailTable from '../Components/Home/DetailTableHome'
import Navbar from '../Components/Navbar/Navbar'
import AvInstituciones from '../Components/Home/av-instituciones'

const AvanceInstituciones = () => {
    return (
        <section>
            <Navbar/>
            <AvInstituciones/>
        </section>
    )
}

export default AvanceInstituciones