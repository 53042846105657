import React from "react";
import Indicadores from "../indicadores-campo/Indicadores-campo";

const IndicadorCampo = () => {
  return (
    <section className="section-container">
      <Indicadores/>
    </section>
  );
};

export default IndicadorCampo;
