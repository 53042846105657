import React from 'react'
import Home from '../Components/Home/Home'
import Navbar from '../Components/Navbar/Navbar'

const WellcomeScreen = () => {
    return (
        <section>
            <Navbar />
            <Home/>
        </section>
    )
}

export default WellcomeScreen
