import React from "react";
import AvInstitucionDetalle from "../avance-instituciones-detalle/av-institucion-detalle";

const AvInstituciones = () => {
  return (
    <section className="section-container">
      <AvInstitucionDetalle/>
    </section>
  );
};

export default AvInstituciones;
