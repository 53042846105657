import React from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import WellcomeScreen from "./Pages/WellcomeScreen";
import Login from "./User/Login";
import TableScreen from "./Pages/TableScreen";
import AvanceInstituciones from "./Pages/Avance-Instituciones";
import AvanceInstitucionesDetalle from "./Pages/Avance-Instituciones-Detalle";
import IndicadoresCampo from "./Pages/Indicadores-campo";
import Encuestadores from "./Pages/Encuestadores";

const isAuthenticated = () => {
  const token = localStorage.getItem('token'); // Suponiendo que el token se almacena en localStorage
  return !!token;
};

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    isAuthenticated()
      ? <Component {...props} />
      : <Redirect to='/login' />
  )} />
);

const Routers = () => {

  return (
    <Router>
      <div>
        <Switch>
          <Route path="/login" component={Login} />
          <PrivateRoute path="/home" component={WellcomeScreen} />
          <PrivateRoute path="/avance-instituciones" component={AvanceInstituciones} />
          <PrivateRoute path="/avance-instituciones-detalle" component={AvanceInstitucionesDetalle} />
          <PrivateRoute path="/indicadores-campo" component={IndicadoresCampo} />
          <PrivateRoute path="/encuestadores" component={Encuestadores} />
          <PrivateRoute path="/detailTable/:tableId/:nomInstitucion" component={TableScreen} />
          {/* Si ninguna de las rutas anteriores coincide, redirige a /login */}
          <Redirect to="/home" />
        </Switch>
      </div>
    </Router>
  );
};

export default Routers;
