import React, { useState, useEffect } from 'react';
import './Navbar.css'

const Navbar = () => {

  const [localUser, setLocalUser] = useState(0);

  const onLogOut = () => {
    localStorage.clear();
    window.location.href = "/login";
    // window.location.href = completeSsoLogout;
  }

  // Usar useEffect para leer el valor del localStorage cuando el componente se monta
  useEffect(() => {
    const isLocalUser = parseInt(localStorage.getItem('user_id'));
    if (isLocalUser) {
      setLocalUser(isLocalUser);
    }
  }, []); // El array vacío [] significa que este useEffect se ejecutará solo una vez, cuando el componente se monte

    return (
      <div>
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
          <div className="w-100 d-flex justify-content-between align-items-center">
            <a className="navbar-brand" href="/home">
              <img 
                src="../../img/cadem/logo-cadem.jpeg" 
                alt="Logo Cadem" 
                className="logos-img"
              />
            </a>
            <div className="navbar-brand pt-2 pb-2">
              <p className="text-center fw-bold mb-0">
                Estudio de satisfacción de usuarios. 
              </p>
              <p 
                className="text-center fs-6 mb-0"
                style={{ color: '#0054a8' }}
              >
                Mejores servicios del estado para las personas.
              </p>
            </div> 
            <a className="float-right" href="/home">
              <img 
                src="../../img/cadem/logosecretaria.png" 
                alt="Logo Cadem" 
                className="logos-img float-end"
              />
            </a>
            <div>
            <button className='btn btn-outline-dark' onClick={onLogOut}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bibi-box-arrow-right" viewBox="0 0 16 16">
            <path fillRule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z"/>
            <path fillRule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"/>
            </svg>
            </button>

            {/* <button className='logout' type='button' onClick={onLogOut}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bibi-box-arrow-right" viewBox="0 0 16 16">
            <path fillRule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z"/>
            <path fillRule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"/>
            </svg>
            </button> */}
          </div>
          </div>
        </div>
      </nav>
      <div style={{paddingTop:30}}>
      <ul className="nav justify-content-center">
        <li className="nav-item">
          <a className="nav-link" href="/home">Avance Total</a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="/avance-instituciones">Avance instituciones y canales</a>
        </li>
        {/* {localUser === 1 ? ( */}
        <li className="nav-item">
          <a className="nav-link" href="/avance-instituciones-detalle">Avance instituciones canales (Detalle)</a>
        </li>
        {/* ) : null } */}
        <li className="nav-item">
          <a className="nav-link" href="/indicadores-campo">Indicadores de campo (Telefónico)</a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="/encuestadores">Encuestadores</a>
        </li>
      </ul>
    </div>
      </div>
      
      
    );
}

export default Navbar
