import React, { useState } from "react";
import Swal from 'sweetalert2'
import "./login.css";
import { API_BASE_URL } from "../Config/config";
import axios from "axios";

import apiInstance from "../Config/interceptor";
import Loading from "../Components/shared/Loading/Loading";

const Login = ({ history }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [user, setCredentials] = useState({
    username: ''
    , password: ''
  });
  const aux = apiInstance;
  // console.log(aux)
  const handleSubmit = async (event) => {
    event.preventDefault();
    // console.log(user)
    // console.log('Estamos en el HandleSubmit:')

    setIsLoading(true);
    let response = await axios.post(API_BASE_URL + "/login", { username: user.username, password: user.password });
    // console.log(response)
    if (response.data.status === 200) {
      localStorage.setItem('token', response.data.token); //cambiar a token
      localStorage.setItem('rol', 'rol-admin');
      localStorage.setItem('id_rol', response.data.rol_id);
      localStorage.setItem('user_id', response.data.user_id);
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 1200,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        },
        didDestroy: () => {
          setIsLoading(false);
          window.location.href = '/home';
        }
      });
      Toast.fire({
        icon: 'success',
        title: 'Iniciado sesión con éxito'
      });
    } else {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      });
      Toast.fire({
        icon: 'error',
        title: 'Usuario y/o contraseña inavlidos.'
      });
      setIsLoading(false);
    }
  };

  return (
<>
    <div className="global-container">
      {isLoading ? <Loading loading={isLoading} /> : null}
      <div className="card login-form">
        <div className="card-body">
          <div className="text-center">
            <img
              className="login-logo"
              src="img/cadem/logo-cadem.jpeg"
              alt="Logo Cadem"
            />
          </div>
          <h3 className="card-title text-center">Iniciar Sesión.</h3>
          <div className="card-text">
            <form onSubmit={handleSubmit}>
              <div className="row form-group mb-2">
                <div className="col-md-4 mt-2 text-center">
                  Usuario:
                </div>
                <div className="col-md-8">
                  <input
                    type="text"
                    name="username"
                    onChange={e => setCredentials({ ...user, username: e.target.value })}
                    value={user.email}
                    className="form-control form-control-sm"
                    placeholder="Usuario"
                  />
                </div>
              </div>
              <div className="row form-group">
                <div className="col-md-4 text-center mt-3">
                  Contraseña:
                </div>
                <div className="col-md-8">
                  <input
                    type="password"
                    name="password"
                    value={user.password}
                    onChange={e => setCredentials({ ...user, password: e.target.value })}
                    className="mt-2 form-control form-control-sm"
                    placeholder="contraseña"
                  />
                </div>
              </div>
              <div className="row mt-2 text-center">
                <div className="">
                  <button
                    type="submit"
                    className="btn btn-primary btn-block mt-4 mb-2"
                    disabled={isLoading}
                  >
                    Iniciar sesión.
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default Login;
