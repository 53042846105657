import React, { useEffect, useState } from "react";
import "./table.css";
//import instituciones from "../../json/instituciones.json"
import { API_BASE_URL } from "../../Config/config";
import axios from "axios";
import { Link } from "react-router-dom";
import Loading from "../shared/Loading/Loading";
import { cardHome, getDiasHome, apiDownloadExcelHome } from "../../service/Service";


const DataTable = () => {
  const [dataTable, setDataTable] = useState([]);
  const [cardsHome, setCardsHome] = useState([]);
  const [cardsCati, setCardsCati] = useState([]);
  const [cardsWeb, setCardsWeb] = useState([]);
  const [isloading, setIsLoading] = useState(false);
  const [isloadingtabla, setIsLoadingtabla] = useState(false);

  useEffect(() => {
    const fetchcard = async () => {
      setIsLoading(true);

      try {
        const response = await cardHome();
        // console.log(response.data.data);
        const data = response.data.data;
        setCardsHome(data?.total);
        setCardsCati(data?.cati);
        setCardsWeb(data?.limesurvey);
      } catch (error) {
        // Manejo de errores
      }

      setIsLoading(false);
    };

    const fetchDias = async () => {
      setIsLoadingtabla(true);

      try {
        const response = await getDiasHome();
        setDataTable(response.data.data);
      } catch (error) {
        // Manejo de errores
      }

      setIsLoadingtabla(false);
    };

    fetchDias();
    fetchcard();
  }, [])

  const downloadExcelHome = () => {
    apiDownloadExcelHome().then((res) => {
      if ( parseInt(res.status) === 200 ) {
        const {status, msg, data} = res.data;
        if (parseInt(status) === 200 ) {
          console.log(msg);
          window.open(data.url, '_blank');
        } else {
          console.log('Ocurrió un error al descargar los datos.', msg);
        }
      }
    });
  }
  
  return (
    <div >
      <div className="row" style={{ paddingTop: 50 }}>
        <div className="col-sm-4 mb-3 mb-sm-0" style={{ paddingLeft: 100 }}>
          <div className="card">
            <div className="card-body">
              <h5 className="card-title" style={{ textAlign: 'center', fontSize: 30 }}>Objetivo</h5>
              {isloading ? (
                <div className="d-flex justify-content-center">
                <div className="spinner-border" style={{color:"#0557aa"}} role="status">
                </div>
                </div>
              ) : (
                <p className="card-text" style={{ textAlign: 'center', fontSize: 30 }}>{cardsHome.objetivo}</p>
              )}
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title" style={{ textAlign: 'center', fontSize: 30 }}>Logrado</h5>
              {isloading ? (
                <div className="d-flex justify-content-center">
                <div className="spinner-border" style={{color:"#0557aa"}} role="status">
                </div>
                </div>
              ) : (
                <p className="card-text" style={{ textAlign: 'center', fontSize: 30 }}>{cardsHome.logrado}</p>
              )}
            </div>
          </div>
        </div>
        <div className="col-sm-4" style={{ paddingRight: 100 }}>
          <div className="card">
            <div className="card-body">
              <h5 className="card-title" style={{ textAlign: 'center', fontSize: 30 }}>% Realizado</h5>
              {isloading ? (
                <div className="d-flex justify-content-center">
                <div className="spinner-border" style={{color:"#0557aa"}} role="status">
                </div>
                </div>
              ) : (
                <p className="card-text" style={{ textAlign: 'center', fontSize: 30 }}>{cardsHome.porcentaje_logrado}%</p>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="table-responsive" style={{ width: "80%", margin: "30px auto" }}>
        <table className="table custom-table table-hover table-striped ">
          <thead className="titles">
            <tr >
              <th scope="col">Dias</th>
              <th scope="col">Objetivo</th>
              <th scope="col">Logrado</th>
              <th scope="col">% Realizado</th>
            </tr>
          </thead>
          <tbody>
            {isloadingtabla ? (
                <tr>
                  <td className="text-center" colSpan="4">
                  <div className="d-flex justify-content-center">
                  <div className="spinner-border" style={{color:"#0557aa"}} role="status">
                  </div>
                  </div>
                  </td>
                
                </tr>
              ): dataTable.map((item) => {
              return (
                <tr>
                  <td className="td-nombre">
                    {item.tipo == 'TOTAL' ? <b>{item.dia} {item.fecha}</b> : `${item.dia} (${item.fecha})`}
                  </td>
                  <td>{item.tipo == 'TOTAL' ? <b>{item.objetivo}</b> : (item.objetivo)}</td>
                  <td>{item.tipo == 'TOTAL' ? <b>{item.logrado}</b> : (item.logrado)}</td>
                  <td>{item.tipo == 'TOTAL' ? <b>{item.porcentaje_logrado}%</b> : `${item.porcentaje_logrado}%`}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      {/* Nuevo codigo */}
      <h1 style={{ textAlign: 'center', fontSize: 30 }}>Cati</h1>
      <div className="row">
        <div className="col-sm-4 mb-3 mb-sm-0" style={{ paddingLeft: 100 }}>
          <div className="card">
            <div className="card-body">
              <h5 className="card-title" style={{ textAlign: 'center', fontSize: 30 }}>Objetivo</h5>
              {isloading ? (
                <div className="d-flex justify-content-center">
                <div className="spinner-border" style={{color:"#0557aa"}} role="status">
                </div>
                </div>
              ) : (
                <p className="card-text" style={{ textAlign: 'center', fontSize: 30 }}>{cardsCati.objetivo}</p>
              )}
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title" style={{ textAlign: 'center', fontSize: 30 }}>Logrado</h5>
              {isloading ? (
                <div className="d-flex justify-content-center">
                <div className="spinner-border" style={{color:"#0557aa"}} role="status">
                </div>
                </div>
              ) : (
                <p className="card-text" style={{ textAlign: 'center', fontSize: 30 }}>{cardsCati.logrado}</p>
              )}
            </div>
          </div>
        </div>
        <div className="col-sm-4" style={{ paddingRight: 100 }}>
          <div className="card">
            <div className="card-body">
              <h5 className="card-title" style={{ textAlign: 'center', fontSize: 30 }}>% Realizado</h5>
              {isloading ? (
                <div className="d-flex justify-content-center">
                <div className="spinner-border" style={{color:"#0557aa"}} role="status">
                </div>
                </div>
              ) : (
                <p className="card-text" style={{ textAlign: 'center', fontSize: 30 }}>{cardsCati.porcentaje_logrado}%</p>
              )}
            </div>
          </div>
        </div>
      </div>
      <br/>
      <h1 style={{ textAlign: 'center', fontSize: 30 }}>Web</h1>
      <div className="row">
        <div className="col-sm-4 mb-3 mb-sm-0" style={{ paddingLeft: 100 }}>
          <div className="card">
            <div className="card-body">
              <h5 className="card-title" style={{ textAlign: 'center', fontSize: 30 }}>Objetivo</h5>
              {isloading ? (
                <div className="d-flex justify-content-center">
                <div className="spinner-border" style={{color:"#0557aa"}} role="status">
                </div>
                </div>
              ) : (
                <p className="card-text" style={{ textAlign: 'center', fontSize: 30 }}>{cardsWeb.objetivo}</p>
              )}
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title" style={{ textAlign: 'center', fontSize: 30 }}>Logrado</h5>
              {isloading ? (
                <div className="d-flex justify-content-center">
                <div className="spinner-border" style={{color:"#0557aa"}} role="status">
                </div>
                </div>
              ) : (
                <p className="card-text" style={{ textAlign: 'center', fontSize: 30 }}>{cardsWeb.logrado}</p>
              )}
            </div>
          </div>
        </div>
        <div className="col-sm-4" style={{ paddingRight: 100 }}>
          <div className="card">
            <div className="card-body">
              <h5 className="card-title" style={{ textAlign: 'center', fontSize: 30 }}>% Realizado</h5>
              {isloading ? (
                <div className="d-flex justify-content-center">
                <div className="spinner-border" style={{color:"#0557aa"}} role="status">
                </div>
                </div>
              ) : (
                <p className="card-text" style={{ textAlign: 'center', fontSize: 30 }}>{cardsWeb.porcentaje_logrado}%</p>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* Botón de descarga */}
      <div style={{ textAlign: "right", paddingRight: 100 }}>
          {/* <button mat-flat-button color="primary" style="padding: 0 30px 0 10px;" (click)="downloadSupervision()">
            <mat-icon class="material-icons-two-tone" style="padding: 0 10px 0 0;">description</mat-icon>
            <b>DESCARGAR CONSOLIDADO</b>
          </button> */}
          <button className="btn btn-primary" onClick={downloadExcelHome}>
            Descargar Excel
          </button>
        </div>
      <br/>
      <br/>
    </div>
    // <div style={{ width: "80%", margin: "30px auto" }}>
    //   {isLoading ? <Loading loading={isLoading}/> : null}
    //   <div className="table-responsive">
    //     <table className="table custom-table table-hover table-striped ">
    //       <thead className="titles">
    //         <tr >
    //           <th scope="col">N°</th>
    //           <th scope="col">Instituciones</th>
    //           <th scope="col">Muestra teórica</th>
    //           <th scope="col">Muestra lograda</th>
    //           <th scope="col">Muestra restante</th>
    //           <th scope="col">% Realizado</th>
    //         </tr>
    //       </thead>
    //       <tbody>
    //         {dataTable.map((item, key) => {
    //           console.log(item);
    //           let porcentajeLogrado = Math.round((100*item.marco_lograda) / item.muetra_total)
    //           if ( parseInt(porcentajeLogrado) > 100 ) {
    //             porcentajeLogrado = 100;
    //           }
    //           let marcoRestante = item.marco_restante;
    //           if ( parseInt(item.id_institucion) === 11 || parseInt(item.id_institucion) === 10 || parseInt(item.id_institucion) === 2 ){
    //             marcoRestante = 0;
    //           }
    //           return (
    //             <tr key={key}>
    //               <td>{key + 1}</td>
    //               <td className="td-nombre"><Link  to={`/detailTable/${item.id_institucion}/${item.nombre}`} >{item.nombre} </Link></td>
    //               <td>{item.muetra_total}</td>
    //               <td>{item.marco_lograda}</td>
    //               <td>{marcoRestante}</td>
    //               <td>{porcentajeLogrado}%</td>
    //             </tr>
    //           )

    //         })}
    //       </tbody>
    //     </table>
    //   </div>
    // </div>
  );
};

export default DataTable;

