import axios from "axios";
import jwt_decode from 'jwt-decode';

import { API_BASE_URL } from "./config";

let autToken = localStorage.getItem('token') ? localStorage.getItem('token') : null;

axios.interceptors.request.use((request) => {
    request.headers ={
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
        'Authorization': 'Bearer ' + autToken
}
return request
});

if ( autToken ) {
    const tokenDecode = jwt_decode(autToken);
    const fechaActualUnix = Date.now();
    if((fechaActualUnix / 1000) > tokenDecode.exp ){
        console.log(`No estoy logueado: ${Math.round(tokenDecode.exp - (fechaActualUnix / 1000))}`)
        localStorage.removeItem('token');
        localStorage.removeItem('rol');
        localStorage.removeItem('id_rol');
        window.location.href = '/login';
    }else{
        console.log(`Estoy logueado: ${Math.round(tokenDecode.exp - (fechaActualUnix / 1000))}`)
    }
}

export default API_BASE_URL;



// api_instance.interceptors.request.use( request => {
//     console.log(request);
//     return request;
// })


// api_instance.interceptors.response.use( response => {
//     console.log(response);
//     return response;
// })

// axios.interceptors.request.use(
//     console.log('hola intenr')
//     // function (config) {
//     //     // const token = getCookieByName('token');
//     //     const token = localStorage.getItem('token') || '';
//     //     console.log('ESTOY EN EL INTERCEPTOR UWU')
//     //     config.headers = {
//     //         "Access-Control-Allow-Origin": "*",
//     //         "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
//     //         Authorization: "Bearer " + token,
//     //     };

//     //     return config;
//     // },

//     // function (error) {
//     //     return Promise.reject(error);
//     // }
// );

// Add a response interceptor
// axios.interceptors.response.use(
//     function (response) {
//         // console.log(response)
//         if(response.status === 202 && response.config.url?.endsWith('check-credentials')) {
//             // const currentSite = window.location.origin;
//             const data = response.data;
//             console.log(data)
//             // const siteRol = data.user.user.roles.find((item: any) => item.USER_SITE_URL === currentSite);
//             // localStorage.setItem('rol', siteRol?.USER_SITE_ROL || '');
//             // if(!siteRol?.USER_SITE_ROL){
//             //     localStorage.setItem('log-ged', 'not-logged');
//             //     window.location.href = "/unauthorized";
//             // }
//         }
//         return response;
//     },

//     function (error) {
//         console.log(error)
//         if (error.response.data.statusCode === 401) {
//             if (error.response.data.message === "Unauthorized") {
//                 deleteAllCookies();
//                 // window.location.href = completeSsoRedirect;
//             }

//         }
//         return Promise.reject(error);
//     }
// );