import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./table.css";
import instituciones from "../../json/instituciones.json"
import datatable from "../../json/datatable.json";
import { getCanalesInsti, getInstitucion, apiDownloadExcelInstitucionCanales } from "../../service/Service";

const AvInstitucion = () => {
  const [dataInstitucion, setDataInstitucion] = useState([]);
  const [dataInstitucionCati, setDataInstitucionCati] = useState([]);
  const [dataInstitucionWeb, setDataInstitucionWeb] = useState([]);
  const [dataCanales, setDataCanales] = useState([]);
  const [dataCanalesCati, setDataCanalesCati] = useState([]);
  const [dataCanalesWeb, setDataCanalesWeb] = useState([]);
  const [isloading, setIsLoading] = useState(false);
  const [isloadingtabla, setIsLoadingtabla] = useState(false);
  // const randomDemo = (max, min) => Math.floor(Math.random() * (max - min)) + min;
  const datosInstituciones = instituciones
  const dtaTable = datatable

  function TabPane({ children, value, index }) {
    return (
        <div role="tabpanel" hidden={value !== index} style={{ marginTop: '20px' }}>
            {value === index && (
                <div>{children}</div>
            )}
        </div>
    );
}

function Tabs({ children }) {
    const [value, setValue] = useState(0);

    return (
        <div>
            <div>
                {React.Children.map(children, (child, index) => (
                    <button 
                        onClick={() => setValue(index)}
                        style={{
                            cursor: 'pointer',
                            padding: '10px 20px',
                            margin: '0 5px',
                            border: 'none',
                            borderRadius: '5px',
                            backgroundColor: value === index ? '#4CAF50' : '#E0E0E0',
                            color: value === index ? 'white' : 'black',
                            fontWeight: value === index ? 'bold' : 'normal'
                        }}
                    >
                        {child.props.label}
                    </button>
                ))}
            </div>
            <div>
                {React.Children.map(children, (child, index) => {
                    return React.cloneElement(child, { value, index });
                })}
            </div>
        </div>
    );
}


  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        const response = await getCanalesInsti();
        const data = response.data.data;
        setDataCanales(data.total);
        setDataCanalesCati(data.cati);
        setDataCanalesWeb(data.limesurvey);
      } catch (error) {
        // Manejo de errores
      }

      setIsLoading(false);
    };

    const fetchinstitucion = async () => {
      setIsLoadingtabla(true);

      try {
        const response = await getInstitucion();
        const data = response.data.data;
        // console.log(data);
        setDataInstitucion(data.total);
        setDataInstitucionCati(data.cati);
        setDataInstitucionWeb(data.limesurvey);
      } catch (error) {
        // Manejo de errores
      }

      setIsLoadingtabla(false);
    };

    fetchData();
    fetchinstitucion();
  }, [])

  const downloadExcelInstitucionCanales = (pTipo) => {
    apiDownloadExcelInstitucionCanales(pTipo).then((res) => {
      if ( parseInt(res.status) === 200 ) {
        const {status, msg, data} = res.data;
        if (parseInt(status) === 200 ) {
          console.log(msg);
          window.open(data.url, '_blank');
        } else {
          console.log('Ocurrió un error al descargar los datos.', msg);
        }
      }
    });
  }

  return (
    <div style={{ width: "80%", margin: "30px auto" }}>
      <Tabs>
          <TabPane label="TOTAL">
            <div className="table-responsive">
              <table className="table custom-table table-hover table-striped ">
                <thead className="titles">
                  <tr >
                    <th scope="col">Canales</th>
                    <th scope="col">Muestra Teorica</th>
                    <th scope="col">Muestra lograda</th>
                    <th scope="col">% Realizado</th>
                  </tr>
                </thead>
                <tbody>
                  {isloading ? (
                    <tr>
                      <td className="text-center" colSpan="4">
                      <div className="d-flex justify-content-center">
                      <div className="spinner-border" style={{color:"#0557aa"}} role="status">
                      </div>
                      </div>
                      </td>
                    
                    </tr>
                  ) : (
                    dataCanales.map((item, index) => (
                      <tr key={index}>
                        <td className="text-center">{item.nombre_canal} </td>
                        <td className="text-center">{item.muestra_objetivo}</td>
                        <td className="text-center">{item.numero_respuestas}</td>
                        <td className="text-center">{item.porcentaje_respuestas}%</td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
            <div className="table-responsive">
              <table className="table custom-table table-hover table-striped ">
                <thead className="titles">
                  <tr >
                    <th scope="col">Instituciones</th>
                    <th scope="col">Muestra objetivo</th>
                    <th scope="col">Muestra lograda</th>
                    <th scope="col">% Realizado</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    isloadingtabla ? (
                      <tr>
                        <td className="text-center" colSpan="4">
                        <div className="d-flex justify-content-center">
                        <div className="spinner-border" style={{color:"#0557aa"}} role="status">
                        </div>
                        </div>
                        </td>
                      
                      </tr>
                    ):(dataInstitucion.map((institucion, index) => {
                      return(

                      
                        // <RowTable key={index} datosInst={institucion}/>
                        <tr key={index}>
                          <td style={{ textAlign: "start", width: "30%" }}><Link to={`/detailTable/${institucion.id_institucion}/${institucion.nombre}`} >{institucion.nombre} </Link></td>
                          <td style={{ width: "20%" }}>{institucion.muestra_objetivo}</td>
                          <td style={{ width: "20%" }}>{institucion.numero_respuestas}</td>
                          <td style={{ width: "20%" }}>{institucion.porcentaje_respuestas}%</td>
                        </tr>
                      )
                    })
                    )}
                </tbody>
              </table>
              <div style={{ textAlign: "right" }}>
                <button className="btn btn-primary" onClick={() => downloadExcelInstitucionCanales('TOTAL')}>
                  Descargar Excel
                </button>
              </div>
            </div>
          </TabPane>
          <TabPane label="CATI">
          <div className="table-responsive">
              <table className="table custom-table table-hover table-striped ">
                <thead className="titles">
                  <tr >
                    <th scope="col">Canales</th>
                    <th scope="col">Muestra Teorica</th>
                    <th scope="col">Muestra lograda</th>
                    <th scope="col">% Realizado</th>
                  </tr>
                </thead>
                <tbody>
                  {isloading ? (
                    <tr>
                      <td className="text-center" colSpan="4">
                      <div className="d-flex justify-content-center">
                      <div className="spinner-border" style={{color:"#0557aa"}} role="status">
                      </div>
                      </div>
                      </td>
                    
                    </tr>
                  ) : (
                    dataCanalesCati.map((item, index) => (
                      <tr key={index}>
                        <td className="text-center">{item.nombre_canal} </td>
                        <td className="text-center">{item.muestra_objetivo}</td>
                        <td className="text-center">{item.numero_respuestas}</td>
                        <td className="text-center">{item.porcentaje_respuestas}%</td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
            <div className="table-responsive">
              <table className="table custom-table table-hover table-striped ">
                <thead className="titles">
                  <tr >
                    <th scope="col">Instituciones</th>
                    <th scope="col">Muestra objetivo</th>
                    <th scope="col">Muestra lograda</th>
                    <th scope="col">% Realizado</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    isloadingtabla ? (
                      <tr>
                        <td className="text-center" colSpan="4">
                        <div className="d-flex justify-content-center">
                        <div className="spinner-border" style={{color:"#0557aa"}} role="status">
                        </div>
                        </div>
                        </td>
                      
                      </tr>
                    ):(dataInstitucionCati.map((institucion, index) => {
                      return(

                      
                        // <RowTable key={index} datosInst={institucion}/>
                        <tr key={index}>
                          <td style={{ textAlign: "start", width: "30%" }}><Link to={`/detailTable/${institucion.id_institucion}/${institucion.nombre}`} >{institucion.nombre} </Link></td>
                          <td style={{ width: "20%" }}>{institucion.muestra_objetivo}</td>
                          <td style={{ width: "20%" }}>{institucion.numero_respuestas}</td>
                          <td style={{ width: "20%" }}>{institucion.porcentaje_respuestas}%</td>
                        </tr>
                      )
                    })
                    )}
                </tbody>
              </table>
              <div style={{ textAlign: "right" }}>
                <button className="btn btn-primary" onClick={() => downloadExcelInstitucionCanales('CATI')}>
                  Descargar Excel
                </button>
              </div>
            </div>
          </TabPane>
          <TabPane label="WEB">
          <div className="table-responsive">
              <table className="table custom-table table-hover table-striped ">
                <thead className="titles">
                  <tr >
                    <th scope="col">Canales</th>
                    <th scope="col">Muestra Teorica</th>
                    <th scope="col">Muestra lograda</th>
                    <th scope="col">% Realizado</th>
                  </tr>
                </thead>
                <tbody>
                  {isloading ? (
                    <tr>
                      <td className="text-center" colSpan="4">
                      <div className="d-flex justify-content-center">
                      <div className="spinner-border" style={{color:"#0557aa"}} role="status">
                      </div>
                      </div>
                      </td>
                    
                    </tr>
                  ) : (
                    dataCanalesWeb.map((item, index) => (
                      <tr key={index}>
                        <td className="text-center">{item.nombre_canal} </td>
                        <td className="text-center">{item.muestra_objetivo}</td>
                        <td className="text-center">{item.numero_respuestas}</td>
                        <td className="text-center">{item.porcentaje_respuestas}%</td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
            <div className="table-responsive">
              <table className="table custom-table table-hover table-striped ">
                <thead className="titles">
                  <tr >
                    <th scope="col">Instituciones</th>
                    <th scope="col">Muestra objetivo</th>
                    <th scope="col">Muestra lograda</th>
                    <th scope="col">% Realizado</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    isloadingtabla ? (
                      <tr>
                        <td className="text-center" colSpan="4">
                        <div className="d-flex justify-content-center">
                        <div className="spinner-border" style={{color:"#0557aa"}} role="status">
                        </div>
                        </div>
                        </td>
                      
                      </tr>
                    ):(dataInstitucionWeb.map((institucion, index) => {
                      return(

                      
                        // <RowTable key={index} datosInst={institucion}/>
                        <tr key={index}>
                          <td style={{ textAlign: "start", width: "30%" }}>
                          { institucion.nombre != 'TOTAL' ? (
                            <Link to={`/detailTable/${institucion.id_institucion}/${institucion.nombre}`} >{institucion.nombre} </Link>
                          ) : institucion.nombre }
                          </td>
                          <td style={{ width: "20%" }}>{institucion.muestra_objetivo}</td>
                          <td style={{ width: "20%" }}>{institucion.numero_respuestas}</td>
                          <td style={{ width: "20%" }}>{institucion.porcentaje_respuestas}%</td>
                        </tr>
                      )
                    })
                    )}
                </tbody>
              </table>
              <div style={{ textAlign: "right" }}>
                <button className="btn btn-primary" onClick={() => downloadExcelInstitucionCanales('WEB')}>
                  Descargar Excel
                </button>
              </div>
            </div>
          </TabPane>
      </Tabs>
      {/* Botón de descarga */}
    </div>
  );
};

export default AvInstitucion;
