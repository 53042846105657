import axios from "axios";
import { API_BASE_URL } from "../Config/config"; 


export const getInstitucion = async () => {
  return await axios.post(API_BASE_URL + '/api/v1/instituciones/list', {})
};

export const getDetalleCanal = async (id_institucion) => {
  return await axios.post(API_BASE_URL + '/api/v1/instituciones/detalle-canal', {id_institucion:id_institucion})
};

export const cardHome = async () => {
  return await axios.post(API_BASE_URL + '/api/v1/avance_total/detalle', {})
};

export const getDiasHome = async () => {
  return await axios.post(API_BASE_URL + '/api/v1/avance_total/ultimo_7_dias', {})
};

export const getCanalesInsti = async () => {
  return await axios.post(API_BASE_URL + '/api/v1/instituciones/canales', {})
};

export const cardEncuestadores = async () => {
  return await axios.post(API_BASE_URL + '/api/v1/encuestadores/ultimos_7_dias', {})
};

export const cardIndTasas = async () => {
  return await axios.post(API_BASE_URL + '/api/v1/indicadores_campo/tasas', {})
};

export const getIndTasasInstituciones = async () => {
  return await axios.post(API_BASE_URL + '/api/v1/indicadores_campo/instituciones', {})
};

export const getInstitucionCanalesDetalle = async () => {
  return await axios.post(API_BASE_URL + '/api/v1/instituciones/canales-detalle', {})
};

export const apiDownloadExcelHome = async () => {
  return await axios.post(API_BASE_URL + '/api/v1/download/excel-home', {})
};

export const apiDownloadExcelInstitucionCanales = async (pTipo) => {
  const params = {tipo: pTipo}
  return await axios.post(API_BASE_URL + '/api/v1/download/excel-institucion-canales', params)
};

export const apidownloadExcelInstitucionCanalesDetalle = async (pTipo) => {
  const params = {tipo: pTipo}
  return await axios.post(API_BASE_URL + '/api/v1/download/excel-institucion-canales-detalle', params)
};

export const apidownloadExcelIndicadoresCampo = async () => {
  return await axios.post(API_BASE_URL + '/api/v1/download/excel-indicadores-campo', {})
};