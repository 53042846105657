import React from 'react'
import DetailTable from '../Components/Home/DetailTableHome'
import Navbar from '../Components/Navbar/Navbar'
import EncuestadoresHome from '../Components/Home/Encuestadores'

const Encuestadores = () => {
    return (
        <section>
            <Navbar />
           <EncuestadoresHome/>
        </section>
    )
}

export default Encuestadores
