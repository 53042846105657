import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./table.css";
// import instituciones from "../../json/instituciones.json"
import { cardIndTasas, getIndTasasInstituciones, apidownloadExcelIndicadoresCampo } from "../../service/Service";

const Indicadores = () => {
  const [dataTable, setDataTable] = useState([]);
  const [cardsHome, setCardsHome] = useState([]);
  const [isloading, setIsLoading] = useState(false);
  const [isloadingtabla, setIsLoadingtabla] = useState(false);

  // }
  useEffect(() => {
    const fetchcard = async () => {
      setIsLoading(true);

      try {
        const response = await cardIndTasas();
        setCardsHome(response.data.data)
      } catch (error) {
        // Manejo de errores
      }

      setIsLoading(false);
    };

    const fetchIndTasasIntituciones = async () => {
      setIsLoadingtabla(true);

      try {
        const response = await getIndTasasInstituciones();
        setDataTable(response.data.data);
      } catch (error) {
        // Manejo de errores
      }

      setIsLoadingtabla(false);
    };

    fetchIndTasasIntituciones();
    fetchcard();
  }, [])

  const downloadExcelIndicadoresCampo = () => {
    apidownloadExcelIndicadoresCampo().then((res) => {
      if ( parseInt(res.status) === 200 ) {
        const {status, msg, data} = res.data;
        if (parseInt(status) === 200 ) {
          console.log(msg);
          window.open(data.url, '_blank');
        } else {
          console.log('Ocurrió un error al descargar los datos.', msg);
        }
      }
    });
  }


  return (
    <div >
      <div className="row" style={{ paddingTop: 50 }}>
        <div className="col-sm-2 mb-3 mb-sm-0" style={{ paddingLeft: 50 }}>
          <div className="card">
            <div className="card-body">
              <h5 className="card-title" style={{ textAlign: 'center', fontSize: 30 }}>Tasa<br/>Logro</h5>
              {isloading ? (
                <div className="d-flex justify-content-center">
                <div className="spinner-border" style={{color:"#0557aa"}} role="status">
                </div>
                </div>
              ) : (
                <p className="card-text" style={{ textAlign: 'center', fontSize: 30 }}>{cardsHome?.tasa_logro}%</p>
                // <p className="card-text" style={{ textAlign: 'center', fontSize: 30 }}>50%</p>
              )}
            </div>
          </div>
        </div>
        <div className="col-sm-2">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title" style={{ textAlign: 'center', fontSize: 30 }}>Tasa<br/>Respuesta</h5>
              {isloading ? (
                <div className="d-flex justify-content-center">
                <div className="spinner-border" style={{color:"#0557aa"}} role="status">
                </div>
                </div>
              ) : (
                <p className="card-text" style={{ textAlign: 'center', fontSize: 30 }}>{cardsHome?.tasa_respuesta}%</p>
                // <p className="card-text" style={{ textAlign: 'center', fontSize: 30 }}>3.7%</p>
              )}
            </div>
          </div>
        </div>
        <div className="col-sm-2">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title" style={{ textAlign: 'center', fontSize: 30 }}>Tasa<br/>Cooperación</h5>
              {isloading ? (
                <div className="d-flex justify-content-center">
                <div className="spinner-border" style={{color:"#0557aa"}} role="status">
                </div>
                </div>
              ) : (
                <p className="card-text" style={{ textAlign: 'center', fontSize: 30 }}>{cardsHome?.tasa_cooperacion}%</p>
                // <p className="card-text" style={{ textAlign: 'center', fontSize: 30 }}>15%</p>
              )}
            </div>
          </div>
        </div>
        <div className="col-sm-2">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title" style={{ textAlign: 'center', fontSize: 30 }}>Tasa<br/>Rechazo</h5>
              {isloading ? (
                <div className="d-flex justify-content-center">
                <div className="spinner-border" style={{color:"#0557aa"}} role="status">
                </div>
                </div>
              ) : (
                <p className="card-text" style={{ textAlign: 'center', fontSize: 30 }}>{cardsHome?.tasa_rechazo}%</p>
                // <p className="card-text" style={{ textAlign: 'center', fontSize: 30 }}>50%</p>
              )}
            </div>
          </div>
        </div>
        <div className="col-sm-2">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title" style={{ textAlign: 'center', fontSize: 30 }}>Tasa<br/>Contacto</h5>
              {isloading ? (
                <div className="d-flex justify-content-center">
                <div className="spinner-border" style={{color:"#0557aa"}} role="status">
                </div>
                </div>
              ) : (
                <p className="card-text" style={{ textAlign: 'center', fontSize: 30 }}>{cardsHome?.tasa_rechazo}%</p>
                // <p className="card-text" style={{ textAlign: 'center', fontSize: 30 }}>29%</p>
              )}
            </div>
          </div>
        </div>
        <div className="col-sm-2" style={{ paddingRight: 50 }}>
          <div className="card">
            <div className="card-body">
              <h5 className="card-title" style={{ textAlign: 'center', fontSize: 30 }}>Tasa no Contacto</h5>
              {isloading ? (
                <div className="d-flex justify-content-center">
                <div className="spinner-border" style={{color:"#0557aa"}} role="status">
                </div>
                </div>
              ) : (
                <p className="card-text" style={{ textAlign: 'center', fontSize: 30 }}>{cardsHome?.tasa_rechazo}%</p>
                // <p className="card-text" style={{ textAlign: 'center', fontSize: 30 }}>71%</p>
              )}
            </div>
          </div>
        </div>
      </div>
      
      {/* <div className="table-responsive" style={{ width: "80%", margin: "30px auto" }}>
        <table className="table custom-table table-hover table-striped ">
          <thead className="titles">
            <tr >
              <th scope="col" width="40%">Canal</th>
              <th scope="col" width="10%">Tasa Logro</th>
              <th scope="col" width="10%">Tasa Respuesta</th>
              <th scope="col" width="10%">Tasa Cooperación</th>
              <th scope="col" width="10%">Tasa Rechazo</th>
              <th scope="col" width="10%">Tasa Contacto</th>
              <th scope="col" width="10%">Tasa no Contacto</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="td-nombre">Digital</td>
              <td>50%</td>
              <td>3.7%</td>
              <td>15%</td>
              <td>50%</td>
              <td>29%</td>
              <td>71%</td>
            </tr>
            <tr>
              <td className="td-nombre">Telefónico</td>
              <td>50%</td>
              <td>3.7%</td>
              <td>15%</td>
              <td>50%</td>
              <td>29%</td>
              <td>71%</td>
            </tr>
            <tr>
              <td className="td-nombre">Presencial</td>
              <td>50%</td>
              <td>3.7%</td>
              <td>15%</td>
              <td>50%</td>
              <td>29%</td>
              <td>71%</td>
            </tr>
            <tr>
              <td className="td-nombre"><b>Total</b></td>
              <td>50%</td>
              <td>3.7%</td>
              <td>15%</td>
              <td>50%</td>
              <td>29%</td>
              <td>71%</td>
            </tr>
          </tbody>
        </table>
      </div> */}

      <div className="table-responsive" style={{ width: "80%", margin: "30px auto" }}>
        <table className="table custom-table table-hover table-striped ">
          <thead className="titles">
            <tr >
              <th scope="col" width="40%">Instituciones</th>
              <th scope="col" width="10%">Tasa Logro</th>
              <th scope="col" width="10%">Tasa Respuesta</th>
              <th scope="col" width="10%">Tasa Cooperación</th>
              <th scope="col" width="10%">Tasa Rechazo</th>
              <th scope="col" width="10%">Tasa Contacto</th>
              <th scope="col" width="10%">Tasa no Contacto</th>
            </tr>
          </thead>
          <tbody>
            {isloadingtabla ? (
                <tr>
                  <td className="text-center" colSpan="7">
                  <div className="d-flex justify-content-center">
                  <div className="spinner-border" style={{color:"#0557aa"}} role="status">
                  </div>
                  </div>
                  </td>
                
                </tr>
              ): dataTable.map((item) => {
                return (
                  <tr>
                    <td className="td-nombre">
                      {item.nombre_institucion}
                    </td>
                    {/* <td>50%</td>
                    <td>3.7%</td>
                    <td>15%</td>
                    <td>50%</td>
                    <td>29%</td>
                    <td>71%</td> */}
                    <td>
                      {item.tasa_logro}%
                    </td>
                    <td>
                      {item.tasa_respuesta}%
                    </td>
                    <td>
                      {item.tasa_cooperacion}%
                    </td>
                    <td>
                      {item.tasa_rechazo}%
                    </td>
                    <td>
                      {item.tasa_cooperacion}%
                    </td>
                    <td>
                      {item.tasa_rechazo}%
                    </td>
                  </tr>
                )
            })}
          </tbody>
        </table>
        {/* Botón de descarga */}
        <div style={{ textAlign: "right" }}>
          <button className="btn btn-primary" onClick={() => downloadExcelIndicadoresCampo()}>
            Descargar Excel
          </button>
        </div>
      </div>

    </div>
  );
};

export default Indicadores;
