import React from 'react'
import DetailTable from '../Components/Home/DetailTableHome'
import Navbar from '../Components/Navbar/Navbar'
import IndicadorCampo from '../Components/Home/IndicadoresCampo'

const IndicadoresCampo = () => {
    return (
        <section>
            <Navbar />
           <IndicadorCampo/>
        </section>
    )
}

export default IndicadoresCampo
