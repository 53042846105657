import React, { useState, Fragment, useEffect } from "react";
import "./table.css";
import datatable from "../../json/datatable.json";
import tableGroup from "../../json/tablegroup.json";
import { toBeDisabled } from "@testing-library/jest-dom/dist/matchers";
import { useParams, useHistory } from "react-router-dom";
import { getDetalleCanal } from "../../service/Service";

const DetalleInstitucion = () => {
  let history = useHistory();
  const [dataDetalle, setDataDetalle] = useState([]);
  const [isloading, setIsLoading] = useState(false);
  // const randomDemo = (max, min) => Math.floor(Math.random() * (max - min)) + min;
  const { tableId, nomInstitucion } = useParams()
  const jsonDataMetodologia = datatable
  const jsonDataGrupo = tableGroup
  console.log(tableId);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        const response = await getDetalleCanal(tableId);
        setDataDetalle(response.data.data);
      } catch (error) {
        // Manejo de errores
      }

      setIsLoading(false);
    };
    fetchData();
  }, [])
  return (
    <Fragment>
      <div style={{ width: "80%", margin: "30px auto" }}>
        <center><h3>{nomInstitucion}</h3></center>
        <button className="btn btn-sm btn-primary" onClick={() => history.push("/avance-instituciones")}>Volver</button>
        <div style={{ margin: "40px auto" }}>
          <div id="table_1" className="table-responsive">
            <table className="table custom-table table-hover table-striped">
              <thead className="titles">
                <tr>
                  <th scope="col">Canales</th>
                  <th scope="col">Muestra Objetivo</th>
                  <th scope="col">Muestra lograda</th>
                  <th scope="col" colSpan="2">% Realizado</th>
                </tr>
              </thead>
              <tbody>
                
                {isloading ? (
                <tr>
                  <td className="text-center" colSpan="4">
                  <div className="d-flex justify-content-center">
                  <div className="spinner-border" style={{color:"#0557aa"}} role="status">
                  </div>
                  </div>
                  </td>
                
                </tr>
              ):dataDetalle.map((item) => {
                  return (
                    <tr>
                      <th className="text-center">
                        {item.nombre_canal}
                      </th>
                      <th className="text-center">
                        {item.muestra_objetivo}
                      </th>
                      <th className="text-center">
                        {item.numero_respuestas}
                      </th>
                      <th className="text-center">
                        {item.porcentaje_respuestas}%
                      </th>
                    </tr>)
                })}
              </tbody>
            </table>
          </div>
        </div>
        {/* Botón de descarga */}
        <div style={{ textAlign: "right" }}>
          <button className="btn btn-primary">
            Descargar Excel
          </button>
        </div>
      </div>
    </Fragment>
  );
};
export default DetalleInstitucion;