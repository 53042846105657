import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import instituciones from "../../json/instituciones.json"
import { cardEncuestadores } from "../../service/Service";

const Encuestador = () => {
  const [cardsHome, setCardsHome] = useState({});
  const [isloading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchcard = async () => {
      setIsLoading(true);

      try {
        const response = await cardEncuestadores();
        setCardsHome(response.data.data);
      } catch (error) {
        // Manejo de errores
      }

      setIsLoading(false);
    };

    fetchcard();
  }, [])

  return (
    <div >
      <div className="row" style={{paddingTop:50}}>
        {/* <div className="col-sm-3 mb-3 mb-sm-0"></div>
        <div className="col-sm-6 mb-3 mb-sm-0" style={{paddingLeft:100}}>
          <div className="card">
            <div className="card-body">
              <h5 className="card-title" style={{textAlign:'center', fontSize:30}}>Total encuestadores al principio</h5>
              {isloading ? (
                <div className="d-flex justify-content-center">
                <div className="spinner-border" style={{color:"#0557aa"}} role="status">
                </div>
                </div>
              ) : (
                <p className="card-text" style={{ textAlign: 'center', fontSize: 30 }}>100</p>
              )}
            </div>
          </div>
        </div>
        <div className="col-sm-3 mb-3 mb-sm-0"></div> */}
        
        <div className="col-sm-6 mb-3 mb-sm-0 mt-4" style={{paddingLeft:100}}>
          <div className="card">
            <div className="card-body">
              <h5 className="card-title" style={{textAlign:'center', fontSize:30}}>Encuestadores con encuestas últimos 7 días</h5>
              {isloading ? (
                <div className="d-flex justify-content-center">
                <div className="spinner-border" style={{color:"#0557aa"}} role="status">
                </div>
                </div>
              ) : (
                <p className="card-text" style={{ textAlign: 'center', fontSize: 30 }}>
                  {cardsHome?.enc_con_encuestas}
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="col-sm-6 mt-4" style={{paddingRight: 100}}>
          <div className="card">
            <div className="card-body">
              <h5 className="card-title" style={{textAlign:'center', fontSize:30}}>Encuestadores nuevos con encuestas últimos 7 días</h5>
              {isloading ? (
                <div className="d-flex justify-content-center">
                <div className="spinner-border" style={{color:"#0557aa"}} role="status">
                </div>
                </div>
              ) : (
                <p className="card-text" style={{ textAlign: 'center', fontSize: 30 }}>
                  {cardsHome?.enc_new_encuestas}
                </p>
              )}
            </div>
          </div>
        </div>
        {/* Botón de descarga */}
        {/* <div style={{ textAlign: "right", paddingRight: 100 }}>
          <button className="btn btn-primary">
            Descargar Excel
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default Encuestador;
