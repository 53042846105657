import React from "react";
import DetalleInstitucion from "../avance-instituciones/detalleInstitucion";

const DetailTableHome = () => {
  return (
    <section className="section-container">
      <DetalleInstitucion/>
    </section>
  );
};

export default DetailTableHome;
