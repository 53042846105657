import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./table.css";
import instituciones from "../../json/instituciones.json"
import datatable from "../../json/datatable.json";
import { getInstitucionCanalesDetalle, apidownloadExcelInstitucionCanalesDetalle } from "../../service/Service";

const AvInstitucionDetalle = () => {
  const [dataInstitucion, setDataInstitucion] = useState([]);
  const [dataInstitucionCati, setDataInstitucionCati] = useState([]);
  const [dataInstitucionWeb, setDataInstitucionWeb] = useState([]);
  const [isloadingtabla, setIsLoadingtabla] = useState(false);

  function TabPane({ children, value, index }) {
    return (
        <div role="tabpanel" hidden={value !== index} style={{ marginTop: '20px' }}>
            {value === index && (
                <div>{children}</div>
            )}
        </div>
    );
}

function Tabs({ children }) {
    const [value, setValue] = useState(0);

    return (
        <div>
            <div>
                {React.Children.map(children, (child, index) => (
                    <button 
                        onClick={() => setValue(index)}
                        style={{
                            cursor: 'pointer',
                            padding: '10px 20px',
                            margin: '0 5px',
                            border: 'none',
                            borderRadius: '5px',
                            backgroundColor: value === index ? '#4CAF50' : '#E0E0E0',
                            color: value === index ? 'white' : 'black',
                            fontWeight: value === index ? 'bold' : 'normal'
                        }}
                    >
                        {child.props.label}
                    </button>
                ))}
            </div>
            <div>
                {React.Children.map(children, (child, index) => {
                    return React.cloneElement(child, { value, index });
                })}
            </div>
        </div>
    );
}


  useEffect(() => {
    const fetchinstitucion = async () => {
      setIsLoadingtabla(true);

      try {
        const response = await getInstitucionCanalesDetalle();
        const data = response.data.data;
        // console.log(data);
        setDataInstitucion(data.total);
        setDataInstitucionCati(data.cati);
        setDataInstitucionWeb(data.limesurvey);
      } catch (error) {
        // Manejo de errores
      }

      setIsLoadingtabla(false);

    };
    fetchinstitucion();
  }, [])

  const downloadExcelInstitucionCanalesDetalle = (pTipo) => {
    apidownloadExcelInstitucionCanalesDetalle(pTipo).then((res) => {
      if ( parseInt(res.status) === 200 ) {
        const {status, msg, data} = res.data;
        if (parseInt(status) === 200 ) {
          console.log(msg);
          window.open(data.url, '_blank');
        } else {
          console.log('Ocurrió un error al descargar los datos.', msg);
        }
      }
    });
  }

  return (
    <div style={{ width: "80%", margin: "30px auto" }}>
      <Tabs>
          <TabPane label="TOTAL">
            <div className="table-responsive">
              <table className="table custom-table table-hover table-striped ">
                <thead className="titles">
                  <tr >
                    <th scope="col">Instituciones</th>
                    <th scope="col">Canal Digital</th>
                    <th scope="col">Canal Telefónico</th>
                    <th scope="col">Canal Presencial</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    isloadingtabla ? (
                      <tr>
                        <td className="text-center" colSpan="4">
                        <div className="d-flex justify-content-center">
                        <div className="spinner-border" style={{color:"#0557aa"}} role="status">
                        </div>
                        </div>
                        </td>
                      
                      </tr>
                    ):(dataInstitucion.map((institucion, index) => {
                      return(
                        <tr key={index}>
                          <td style={{ textAlign: "start", width: "30%" }}><b>{institucion.nombre}</b></td>
                          <td style={{ width: "20%" }}>{institucion.canal_digital}</td>
                          <td style={{ width: "20%" }}>{institucion.canal_telefonico}</td>
                          <td style={{ width: "20%" }}>{institucion.canal_presencial}</td>
                        </tr>
                      )
                    })
                    )}
                </tbody>
              </table>
              <div style={{ textAlign: "right" }}>
                <button className="btn btn-primary" onClick={() => downloadExcelInstitucionCanalesDetalle('TOTAL')}>
                  Descargar Excel
                </button>
              </div>
            </div>
          </TabPane>
          <TabPane label="CATI">
            <div className="table-responsive">
              <table className="table custom-table table-hover table-striped ">
                <thead className="titles">
                  <tr>
                    <th scope="col">Instituciones</th>
                    <th scope="col">Canal Digital</th>
                    <th scope="col">Canal Telefónico</th>
                    <th scope="col">Canal Presencial</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    isloadingtabla ? (
                      <tr>
                        <td className="text-center" colSpan="4">
                        <div className="d-flex justify-content-center">
                        <div className="spinner-border" style={{color:"#0557aa"}} role="status">
                        </div>
                        </div>
                        </td>
                      
                      </tr>
                    ):(dataInstitucionCati.map((institucion, index) => {
                      return(
                        <tr key={index}>
                          <td style={{ textAlign: "start", width: "30%" }}><b>{institucion.nombre}</b></td>
                          <td style={{ width: "20%" }}>{institucion.canal_digital}</td>
                          <td style={{ width: "20%" }}>{institucion.canal_telefonico}</td>
                          <td style={{ width: "20%" }}>{institucion.canal_presencial}</td>
                        </tr>
                      )
                    })
                    )}
                </tbody>
              </table>
              <div style={{ textAlign: "right" }}>
                <button className="btn btn-primary" onClick={() => downloadExcelInstitucionCanalesDetalle('CATI')}>
                  Descargar Excel
                </button>
              </div>
            </div>
          </TabPane>
          <TabPane label="WEB">
            <div className="table-responsive">
              <table className="table custom-table table-hover table-striped ">
                <thead className="titles">
                  <tr>
                    <th scope="col">Instituciones</th>
                    <th scope="col">Canal Digital</th>
                    <th scope="col">Canal Telefónico</th>
                    <th scope="col">Canal Presencial</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    isloadingtabla ? (
                      <tr>
                        <td className="text-center" colSpan="4">
                        <div className="d-flex justify-content-center">
                        <div className="spinner-border" style={{color:"#0557aa"}} role="status">
                        </div>
                        </div>
                        </td>
                      
                      </tr>
                    ):(dataInstitucionWeb.map((institucion, index) => {
                      return(
                        <tr key={index}>
                          <td style={{ textAlign: "start", width: "30%" }}><b>{institucion.nombre}</b></td>
                          <td style={{ width: "20%" }}>{institucion.canal_digital}</td>
                          <td style={{ width: "20%" }}>{institucion.canal_telefonico}</td>
                          <td style={{ width: "20%" }}>{institucion.canal_presencial}</td>
                        </tr>
                      )
                    })
                    )}
                </tbody>
              </table>
              <div style={{ textAlign: "right" }}>
                <button className="btn btn-primary" onClick={() => downloadExcelInstitucionCanalesDetalle('WEB')}>
                  Descargar Excel
                </button>
              </div>
            </div>
          </TabPane>
      </Tabs>
    </div>
  );
};

export default AvInstitucionDetalle;
