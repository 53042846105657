import React from "react";
import DataTable from "../DataTable/DataTable";

const Home = () => {
  return (
    <section className="section-container">
      <DataTable/>
    </section>
  );
};

export default Home;
