import React from "react";
import Encuestador from "../encuestadores/Encuestadores";


const EncuestadoresHome = () => {
  return (
    <section className="section-container">
      <Encuestador/>
    </section>
  );
};

export default EncuestadoresHome;
