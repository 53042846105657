import React from 'react'
import DetailTable from '../Components/Home/DetailTableHome'
import Navbar from '../Components/Navbar/Navbar'
import AvInstitucionDetalle from '../Components/Home/av-instituciones-detalle'

const AvanceInstitucionesDetalle = () => {
    return (
        <section>
            <Navbar/>
            <AvInstitucionDetalle/>
        </section>
    )
}

export default AvanceInstitucionesDetalle