import React from "react";

import '../Loading/Loading.css'

const Loading = (props) => {
    console.log(props.loading)
    return(
        <div className="loading">Loading&#8230;</div>
    )
}

export default Loading;