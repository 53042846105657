import React from 'react'
import DetailTable from '../Components/Home/DetailTableHome'
import Navbar from '../Components/Navbar/Navbar'

const WellcomeScreen = () => {
    return (
        <section>
            <Navbar />
            <DetailTable/>
        </section>
    )
}

export default WellcomeScreen
